import { mapState } from "vuex";
import { env } from "md-base-tools/env";
// import detailsHeader from "@@/order_detail/detailsHeader.vue";
import lowerdev from "@/service/lowerdev";
import { useRoute } from "vue-router";
import domain from "@/service/domain";
import { orderPayinfo, proPaySuccessCPInfo } from "@api/index";
import MainContent from "@@/home/doubleCols.vue";
import MDRouter from "../../service/router";
import { mdToast } from "../../utils/tools";
export default {
  data() {
    return {
      env: env,
      // order_type: 0,
      cardInfo: {},
      payinfoSuccess: false,
      payinfoData: {
        order_type: 0
      },
      cpinfoData: {},
      //众筹-CP信息
      feed_params_string: "",
      //电商-底部双列请求参数
      downloadShow: false,
      isMobile: env.isMobile()
    };
  },
  components: {
    MainContent
  },
  async setup() {
    const route = useRoute();
    let pay_id = route.query.pay_id || "";
    let fail_next = sessionStorage.getItem("FailNext") || "href";
    return {
      pay_id,
      fail_next // 支付失败，是否需要跳转到订单列表页 href:跳转到订单列表；reload:刷新当前页面
    };
  },
  watch: {},
  mounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnavOnShow(false);
    // eslint-disable-next-line no-undef
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }

    //如果已跳转到支付结果页-清除本地payID
    const pay_id = sessionStorage.getItem("PayId");
    if (pay_id && pay_id == this.pay_id) {
      sessionStorage.removeItem("PayId");
      sessionStorage.removeItem("FailNext");
    }
    this.payinfo();
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  methods: {
    async payinfo() {
      //-1失效 0未支付 2成功
      const {
        status,
        data,
        message
      } = await orderPayinfo({
        pay_id: this.pay_id
      });
      this.payinfoSuccess = true;
      if (status == 0) {
        this.payinfoData = data;
        if (data.pay_status == 2) {
          // 配置双列除ad_positon外额外参数
          if (data.products && data.products.length > 0) {
            let product_ids = data.products.join();
            this.feed_params_string = JSON.stringify({
              page_mall_ids: product_ids
            });
          }
          // eslint-disable-next-line no-undef
          userHeader.backnavOnShow(true);
          if (data.order_type == 4) {
            // 电商
            this.openDown();
          } else {
            // 众筹
            this.cpinfo();
            this.openDown();
          }
        } else if (data.pay_status == -1 || data.pay_status == 0) {
          // 支付失败，跳转至订单列表
          mdToast(data.pay_status == -1 ? "支付失败" : "订单未支付");
          setTimeout(() => {
            if (this.fail_next == "href") {
              window.location.replace(`${domain.wap}/order/order_list`);
            } else {
              window.history.go(-1);
            }
          }, 1000);
        } else {
          mdToast(message);
          setTimeout(() => {
            window.history.go(-1);
          });
        }
      } else {
        mdToast(message);
        setTimeout(() => {
          window.history.go(-1);
        });
      }
    },
    async cpinfo() {
      const {
        status,
        data
      } = await proPaySuccessCPInfo({
        pro_id: this.payinfoData.pro_id
      });
      if (status == 0) {
        this.cpinfoData = data;
      }
    },
    openDown() {
      if (!(this.env.isWeibo() || this.env.isWxApp())) {
        this.downloadShow = true;
      }
    },
    toShop() {
      location.href = "/product/newGoods";
    },
    toMallOrderDetail() {
      MDRouter.goMallOrderDetail(this.payinfoData.order_id);
    },
    toProOrderDetail() {
      MDRouter.goProOrderDetail(this.payinfoData.order_id);
    },
    toCardPrize() {
      window.history.go(-1);
    },
    toScore() {
      if (this.env.isWxApp()) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: `/pages/score/detail/detail`
        });
      } else {
        this.openDown();
      }
    },
    openLink() {
      // eslint-disable-next-line no-undef
      location.href = userHeader.mdlink;
    }
  }
};